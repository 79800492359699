
import Vue from 'vue';
import { Provide, Component } from 'vue-property-decorator';
import axios from '../../plugins/axios.js';
import moment from 'moment';
import TransactionsTrendsChart from './modals/TransactionsTrendsChart.vue';

@Component({
    components: {
        TransactionsTrendsChart,
    },
})
export default class TransactionManagement extends Vue {
    @Provide() private transactions: any = [];
    @Provide() private availableSellers: any = [];
    @Provide() private conditionedTotals: any = [];
    @Provide() private transactionsTrends: any = {};
    @Provide() private queriedCondition: { [index: string]: any } = {
        tradeNo: null,
        createdAt: [
            moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
            moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        ],
        phone: '',
        statuses: [],
        sellerUids: [],
        minimumAmount: null,
        maximumAmount: null,
        page: 1,
    };
    @Provide() private pageSize: number = 10;
    @Provide() private totalTransactions: number = 0;
    @Provide() private loading: boolean = false;
    @Provide() private pickerOptions = {
        shortcuts: [
            {
                text: '今天',
                onClick(picker) {
                    const start = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
                    const end = moment().format('YYYY-MM-DD HH:mm:ss');
                    picker.$emit('pick', [start, end]);
                },
            },
            {
                text: '前一天',
                onClick(picker) {
                    const start = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss');
                    const end = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm:ss');
                    picker.$emit('pick', [start, end]);
                },
            },
            {
                text: '本月(完整月)',
                onClick(picker) {
                    const start = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
                    const end = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
                    picker.$emit('pick', [start, end]);
                },
            },
            {
                text: '本月(至当前)',
                onClick(picker) {
                    const start = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
                    const end = moment().format('YYYY-MM-DD HH:mm:ss');
                    picker.$emit('pick', [start, end]);
                },
            },
            {
                text: '前一月',
                onClick(picker) {
                    const start = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
                    const end = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
                    picker.$emit('pick', [start, end]);
                },
            },
        ],
    };
    private mounted() {
        this.getTransactions();
    }
    private queryTransactions() {
        this.queriedCondition.page = 1;
        this.getTransactions();
    }
    private changePage(page: number) {
        this.queriedCondition.page = page;
        this.getTransactions();
    }
    private getTransactions() {
        if (!this.queriedCondition.phone) {
            if (!this.queriedCondition.createdAt) {
                return this.$message.error('请选择订单发起时间');
            }
            if (this.queriedCondition.createdAt) {
                const start = this.queriedCondition.createdAt[0];
                const end = this.queriedCondition.createdAt[1];
                if (moment(end).diff(moment(start), 'days') > 100) {
                    return this.$message.error('订单发起时间最大可选时间跨度为 100 天');
                }
            }
        }
        this.queriedCondition.minimumAmount = parseInt(this.queriedCondition.minimumAmount, 0) || null;
        this.queriedCondition.maximumAmount = parseInt(this.queriedCondition.maximumAmount, 0) || null;
        const params = this.queriedCondition;
        if (this.queriedCondition.createdAt) {
            params.start = this.queriedCondition.createdAt[0];
            params.end = this.queriedCondition.createdAt[1];
        } else {
            params.start = null;
            params.end = null;
        }
        this.loading = true;
        axios.get('/api/v1/sales/get-transactions', params).then((response: any) => {
            this.loading = false;
            this.transactions = response.transactions;
            this.totalTransactions = response.total;
            this.conditionedTotals = [response.conditionedTotals];
            this.availableSellers = response.sellers;
            this.transactionsTrends = response.conditionedDailyTrends;
        }).catch((error) => {
            this.$message.error(error);
        });
    }
    private changeFilter(filter: any) {
        if (filter.hasOwnProperty('status')) {
            this.queriedCondition.statuses = filter.status;
        }
        if (filter.hasOwnProperty('seller')) {
            this.queriedCondition.sellerUids = filter.seller;
        }
        this.queriedCondition.page = '1';
        this.getTransactions();
    }
}
